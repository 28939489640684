import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Button, Grid } from '@mui/material'
import { ContentCopy, PersonAddAlt1, Send } from '@mui/icons-material'
import parse from 'html-react-parser'

import { formatDate } from '../../../utils/functions'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { Column, PaginatedTable } from '../../../components/paginated-table'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'
import { CustomerUser, getCustomerUsersList, setPageNumber, setPageSize } from './get-customer-users-slice'
import { ActionMenu, ContextMenuItemProps, createContextMenuItem } from '../../../components/action-menu'
import { resendInviteCustomerUser, resetResendInviteCustomerUser } from './resend-invite-customer-user-slice'
import { usePermission } from '../../../hooks/use-permission'
import { reactivateCustomerUser, resetReactivateCustomerUser } from './reactivate-customer-user-slice'

interface CustomerUsersListProps {
  open: boolean;
  id: number
  handleClose: () => void;
  onClickInviteTeamMemberButton?: () => void;
}

const PaginationComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='flex-end'>
    <TablePagination page={page}
      onTablePageChange={onTablePageChange}
      onTablePageSizeChange={onTablePageSizeChange} />
  </Grid>

const CustomerUsersList = ({
  handleClose,
  open,
  id,
  onClickInviteTeamMemberButton
}: CustomerUsersListProps) => {
  const { getCustomerUsersState, resendInviteCustomerUserState, reactivateCustomerUserState } = useAppSelector(state => state)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [writeInviteTeamMember] = usePermission([AnilityScopes.Write.InviteTeamMember])
  const { items, totalCount, pageNumber, pageSize } = getCustomerUsersState
  const loading = getCustomerUsersState.loading === 'loading' || resendInviteCustomerUserState.loading === 'loading' || reactivateCustomerUserState.loading === 'loading'
  const error = getCustomerUsersState.error || resendInviteCustomerUserState.error || reactivateCustomerUserState.error

  const columns: Column[] = [{
    id: 'tempFirstName',
    label: 'First Name',
    minWidth: 100
  }, {
    id: 'tempLastName',
    label: 'Last Name',
    minWidth: 100

  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    minWidth: 100
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100
  },
  {
    id: 'lastLoginDateTime',
    label: 'Last Login Date',
    minWidth: 100
  }]

  useEffect(() => {
    if (!id || isNaN(+id)) {
      navigate('..')
    } else {
      dispatch(getCustomerUsersList({
        customerId: id
      }))
    }
  }, [id, pageSize, pageNumber])

  useEffect(() => {
    dispatch(resetResendInviteCustomerUser())
    dispatch(resetReactivateCustomerUser())
  }, [])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }
  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleResendInvite = (item: CustomerUser) => {
    setSuccessMessage('')
    dispatch(resendInviteCustomerUser({
      customerUserId: item.id,
      onSuccess: () => {
        setSuccessMessage(`Resend invite for ${item.email} is successful.`)
      }
    }))
  }

  const handleCopyRegistrationLink = (item: CustomerUser) => {
    setSuccessMessage('')
    navigator.clipboard.writeText(item.registrationLink)
    setSuccessMessage(`Link for email ${item.email} is successfully copied.`)
  }

  const handleReactivateUser = (item: CustomerUser) => {
    setSuccessMessage('')
    dispatch(reactivateCustomerUser({
      customerUserId: item.id,
      onSuccess: (response) => {
        const message = response.status === 'Pending'
          ? `Pending user with email <a href="mailto:${item.email}">${item.email}</a> is reactivated. A new invite email has been sent.`
          : `User with email <a href="mailto:${item.email}">${item.email}</a> is reactivated. User has been notified via email.`
        setSuccessMessage(message)
        dispatch(getCustomerUsersList({
          customerId: id
        }))
      }
    }))
  }

  const generateContextMenu = useCallback((item: CustomerUser): MenuListItemProps[] => {
    return [
      ResendInviteMenuItem({
        show: item.status === 'Pending' && writeInviteTeamMember,
        onClick: () => handleResendInvite(item)
      }),
      CopyInviteLink({
        show: item.status === 'Pending' && writeInviteTeamMember,
        onClick: () => item.registrationLink && handleCopyRegistrationLink(item)
      }),
      ReactivateUserMenuItem({
        show: item.isDeleted && writeInviteTeamMember,
        onClick: () => handleReactivateUser(item)
      })
    ]
  }, [writeInviteTeamMember])

  const formattedItems = items.map((item) => { return { ...item, lastLoginDateTime: formatDate(item.lastLoginDateTime ?? '', 'YYYY/MM/DD HH:mm:ss') } })
  const page = { totalCount, size: pageSize, number: pageNumber }

  return (
    <>
      <CustomDialogForm
        handleSubmit={() => { }}
        maxWidth={950}
        disablePrimary={true}
        onClose={() => {
          handleClose()
        }}
        primaryText=''
        title='Team Members'
        open={open}
        showCloseDialogIcon={true}
        applyFixedWidth={true}
        hideDialogActions={true}
      >
        <Grid container pb={1} pt={3}>
          <PaginatedTable
            columns={columns}
            items={formattedItems}
            headerComponent={
              <Box display="flex" sx={{ minWidth: '100%', height: 'auto', justifyContent: error || successMessage ? 'space-between' : 'flex-end', alignItems: 'center' }}>
                {successMessage
                  ? <Alert severity='success'>{parse(successMessage)}</Alert>
                  : error && <Alert severity='error'>{error}</Alert>}
                {!!onClickInviteTeamMemberButton && <Button variant="contained" onClick={onClickInviteTeamMemberButton}>Invite team member</Button>}
              </Box>
            }
            footerComponent={
              <PaginationComponent
                page={page}
                onTablePageChange={handlePageChange}
                onTablePageSizeChange={handlePageSizeChange}
              />
            }
            renderMenuRowActions={(item, index, itemLength) => (
              <ActionMenu
                contextMenuIndicatorMarginLeft={0.5}
                menuListItems={generateContextMenu(item)}
                index={index}
                itemLength={itemLength}
              />
            )}
          />
        </Grid>
      </CustomDialogForm>
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default CustomerUsersList

export const ResendInviteMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Resend Invite', <Send />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}

export const CopyInviteLink = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Copy Link', <ContentCopy />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}

export const ReactivateUserMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Reactivate', <PersonAddAlt1 />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}
