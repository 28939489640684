import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import PaginatedTable, { Column } from '../../../components/paginated-table'
import { TableSearch, TableSearchProps } from '../../../components/table-search'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'
import { EntityAssessed, getEntityAssessedList, setPageNumber, setPageSize, setSearchText } from './get-entity-assessed-list-slice'

const columns: Column[] = [{
  id: 'entityName',
  label: 'Entity Name',
  minWidth: 400,
  align: 'left'
}]

const EntityAssessedList = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getEntityAssessedListState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, searchText } = getEntityAssessedListState
  const loading = getEntityAssessedListState.loading === 'loading'

  const [localSearch, setLocalSearch] = useState(searchText)

  useEffect(() => {
    if (getEntityAssessedListState.loading === 'idle') {
      dispatch(getEntityAssessedList())
    }
  }, [getEntityAssessedListState.loading])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value)
  }

  const handleResetSearch = () => {
    setLocalSearch('')
    dispatch(setSearchText(''))
  }

  const handleSearch = () => {
    dispatch(setSearchText(localSearch))
  }

  const handleViewEntityAssessed = (
    entityAssessed: EntityAssessed
  ) => {
    navigate(`${entityAssessed.id}`)
  }

  const getTableCellStyle = () => ({
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    cursor: 'pointer'
  })

  const page = { totalCount, size: pageSize, number: pageNumber }

  const formattedEntityAssessedList = items.map((item) => {
    return {
      ...item,
      rowInlineStyles: getTableCellStyle()
    }
  })
  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <PaginatedTable
          columns={columns}
          items={formattedEntityAssessedList}
          headerComponent={<SearchComponent
            searchValue={localSearch}
            onSearchTextChange={handleSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={handleResetSearch} />}
          footerComponent={
            <PaginationComponent
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />}
          onRowClick={handleViewEntityAssessed}
        />
      </Box >
      <AnilityBackdrop open={loading} />
    </>
  )
}

const SearchComponent = ({ searchValue, onSearchClick, onResetSearchClick, onSearchTextChange }: TableSearchProps) =>
  <Grid container>
    <Grid item xs={10}>
      <Stack spacing={1.5}>
        <Typography variant='h3'>Entities Assessed</Typography>
        <Typography color={({ palette }) => palette.text.secondary}>List of Anility entity assessed</Typography>
      </Stack>
    </Grid>
    <Grid container item xs={2} justifyContent="flex-end" alignItems="flex-end">
      <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
    </Grid>
  </Grid>

const PaginationComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <>
    <Grid container>
      <Grid item xs={8} py={2}>
      </Grid>
      <Grid item xs={4}>
        <TablePagination page={page}
          onTablePageChange={onTablePageChange}
          onTablePageSizeChange={onTablePageSizeChange} />
      </Grid>
    </Grid>
  </>

export default EntityAssessedList
