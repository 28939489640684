import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { CompletedAssessmentRequestsItem, getCompletedAssessmentRequests, resetLoadingStatus, setPageNumber, setPageSize, setSearchText } from './get-completed-assessment-requests-slice'
import { useNavigate } from 'react-router-dom'
import { PaginatedTable, Column } from '../../../components/paginated-table'
import { formatDate, formatDuration } from '../../../utils/functions'
import { Box } from '@mui/material'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { InternalNoteContextMenuItem } from '../components/assessments-action-menu'
import { InternalNotes } from '../../../components/internal-notes'
import { setSelectedAssessmentInternalNote } from '../../../components/set-internal-notes-slice'
import { usePermission } from '../../../hooks/use-permission'
import { DownloadLink } from '../../../components/download-link'
import { ActionMenu } from '../../../components/action-menu'
import { AssessmentFooter } from '../components/assessment-footer'
import { TableHeader } from '../../../components/table-header'

export const columns: Column[] = [
  {
    id: 'assignedAnalyst',
    label: 'Assigned To',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'orderDate',
    label: 'Ordered Date',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'reportConfigurationId',
    label: 'Assessment Type',
    minWidth: 90
  },
  {
    id: 'customer',
    label: 'Customer Name',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'entityAssessed',
    label: 'Entity Assessed Name',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'contractName',
    label: 'Assessment Subject',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'completedDateUtc',
    label: 'Completed',
    minWidth: 100
  },
  {
    id: 'entityAssessedResponseTime',
    label: 'Entity Assessed Response Time',
    minWidth: 150
  },
  {
    id: 'timeTakenToCreateAssessment',
    label: 'Time Taken to Create Assessment',
    minWidth: 150
  },
  {
    id: 'timeTakenToDeliverAssessment',
    label: 'Overall Time Taken to Deliver Assessment',
    minWidth: 170
  },
  {
    id: 'assessmentReportLink',
    label: 'Report',
    minWidth: 100
  }
]

const getTableCellStyle = () => ({
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  cursor: 'pointer'
})

const CompletedAssessments = () => {
  const dispatch = useAppDispatch()
  const { getCompletedAssessmentRequestsState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, searchText } = getCompletedAssessmentRequestsState
  const loading = getCompletedAssessmentRequestsState.loading === 'loading'
  const navigate = useNavigate()

  const [localSearch, setLocalSearch] = useState(searchText)
  const [showInternalNotes, setShowInternalNotes] = useState(false)
  const [writeInternalNotes] = usePermission([AnilityScopes.Write.InternalNotes])

  useEffect(() => {
    setLocalSearch('')
    dispatch(resetLoadingStatus(true))
  }, [])

  useEffect(() => {
    if (getCompletedAssessmentRequestsState.loading === 'idle') {
      dispatch(getCompletedAssessmentRequests({
        status: 'Completed'
      }))
    }
  }, [getCompletedAssessmentRequestsState.loading])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value)
  }

  const handleResetSearch = () => {
    setLocalSearch('')
    dispatch(setSearchText(''))
  }

  const handleSearch = () => {
    dispatch(setSearchText(localSearch))
  }

  const handleViewReportWorkspace = (
    assessmentRequest: CompletedAssessmentRequestsItem
  ) => {
    navigate(`/report/report-workspace/view/${assessmentRequest.reportWorkspaceId}`, {
      state: {
        source: '/report/completed-assessments'
      }
    })
  }

  const handleInternalNotesClick = (item: CompletedAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(setSelectedAssessmentInternalNote(item.id))
  }

  const generateContextMenu = (item: CompletedAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      })
    ]
  }

  const formattedCompletedAssessments = items.map((item) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      completedDateUtc: formatDate(item.completedDateUtc),
      assignedAnalyst: item.assignedAnalyst?.label,
      entityAssessedResponseTime: formatDuration(item.entityAssessedResponseTime),
      timeTakenToCreateAssessment: formatDuration(item.timeTakenToCreateAssessment),
      timeTakenToDeliverAssessment: formatDuration(item.timeTakenToDeliverAssessment),
      rowInlineStyles: getTableCellStyle(),
      assessmentReportLink: item.assessmentReportLink &&
        (<DownloadLink url={item.assessmentReportLink}><CloudDownloadOutlinedIcon /></DownloadLink>)
    }
  })

  const page = { totalCount, size: pageSize, number: pageNumber }

  return (
    <Box p={2} sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      position: 'relative'
    }}>
      <PaginatedTable
        columns={columns}
        items={formattedCompletedAssessments}
        headerComponent={
          <TableHeader
            title="Completed"
            description="Assessments that have been completed"
            searchValue={localSearch}
            onSearchTextChange={handleSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={handleResetSearch}
          />
        }
        footerComponent={
          <AssessmentFooter
            page={page}
            onTablePageChange={handlePageChange}
            onTablePageSizeChange={handlePageSizeChange}
          />
        }
        onRowClick={handleViewReportWorkspace}
        renderMenuRowActions={(item, index, itemLength) => {
          return (
            <ActionMenu
              isContextMenuDisabled={false}
              contextMenuIndicatorMarginLeft={0.5}
              menuListItems={generateContextMenu(item)}
              index={index}
              itemLength={itemLength}
            />
          )
        }}
      />
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
      <AnilityBackdrop open={loading} />
    </Box>
  )
}

export default CompletedAssessments
