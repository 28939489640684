import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'

interface GetCustomerUsersState {
  items: CustomerUser[]
  pageSize: number
  pageNumber: number
  totalCount: number
  searchText: string
  loading: LoadingType
  error: string
}

export interface CustomerUser {
  id: number
  tempFirstName: string
  tempLastName: string
  email: string
  status: string
  lastLoginDateTime: string | null
  registrationLink: string
  isDeleted: boolean
}

export interface GetCustomerUsers {
  items: CustomerUser[]
  pageNumber: number
  totalCount: number
}

export const DEFAULT_PAGE_SIZE = 5

const initialState: GetCustomerUsersState = {
  items: [],
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  totalCount: 0,
  searchText: '',
  loading: 'idle',
  error: ''
}

interface GetCustomerUsersPayload {
  customerId: number
}

export const getCustomerUsersList = createAsyncThunk<
  GetCustomerUsers,
  GetCustomerUsersPayload,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('customers/getCustomerUsers', async (request, thunkAPI) => {
  const { tokenState, getCustomerUsersState } = thunkAPI.getState()
  const params = new URLSearchParams()
  const { pageNumber, pageSize } = getCustomerUsersState

  params.append('pageNumber', pageNumber.toString())
  params.append('pageSize', pageSize.toString())

  try {
    const response = await axios.get<GetCustomerUsers>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}/users?${params.toString()}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getCustomerUsersSlice = createSlice({
  name: 'get-customer-users',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerUsersList.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getCustomerUsersList.fulfilled, (state, { payload }) => {
      state.items = payload.items
      state.pageNumber = payload.pageNumber
      state.totalCount = payload.totalCount
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getCustomerUsersList.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { setSearchText, setPageSize, setPageNumber } = getCustomerUsersSlice.actions
