import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { AllPermissions, AnyPermissions, usePermission } from '../hooks/use-permission'
import { NavType } from '../types/nav-type'
import { useLocation } from 'react-router-dom'
import { LocationState } from '../interfaces/location-state'

export interface MenuListItemProps {
  type: NavType;
  title: string;
  icon?: React.ReactElement;
  to?: string;
  show?: boolean;
  permissions: string[];
  condition?: 'All' | 'Any';
  state?: Record<string, string>
  onClick?: () => void;
};

interface GroupProps {
  title: string;
  icon?: React.ReactElement;
}

const Group = ({
  title,
  icon
}: GroupProps) => {
  return <MenuItem sx={{ paddingLeft: 0, marginBottom: '0.5rem' }}>
    {icon && (<ListItemIcon>{icon}</ListItemIcon>)}
    <ListItemText
      className='wrapped-text'
      primary={<Typography variant="label" sx={{ fontSize: '1rem', fontWeight: 700, color: (theme) => theme.palette.text.secondary }}>{title}</Typography>}
    />
  </MenuItem>
}

interface ItemProps {
  title: string;
  icon?: React.ReactElement;
  isActive: boolean;
  onClick?: () => void;
}

const Item = ({
  title,
  icon,
  isActive,
  onClick
}: ItemProps) => {
  return <MenuItem sx={{ paddingLeft: 0 }} onClick={onClick}>
    {icon && (<ListItemIcon sx={{
      backgroundColor: (theme) => isActive ? theme.palette.primary.light : theme.palette.primary.main
    }}>{icon}</ListItemIcon>)}
    <ListItemText
      className='wrapped-text'
      primary={title}
    />
  </MenuItem>
}

export const MenuListItem = ({
  type,
  title,
  icon,
  to,
  permissions,
  condition,
  show,
  onClick,
  state
}: MenuListItemProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const permissionArray = usePermission(permissions)
  const handleMenuItemClick = React.useCallback(() => {
    onClick ? onClick() : navigate(to ?? '/', { state })
  }, [onClick, to, state])
  const [displayMenu, setDisplayMenu] = useState(false)

  const currentState = location.state as LocationState | undefined
  const source = currentState?.source
  const isActive = source === to || (!source && location.pathname.startsWith(to!) && currentState?.status === state?.status)
  useEffect(() => {
    if (!show) {
      return
    }
    if (permissionArray.length) {
      const result = (condition === undefined || condition === 'All')
        ? AllPermissions(permissionArray)
        : AnyPermissions(permissionArray)
      setDisplayMenu(result)
    } else {
      setDisplayMenu(true)
    }
  }, [show, permissionArray, condition])

  if (!displayMenu) {
    return null
  }
  return (
    type === 'NavItem'
      ? <Item title={title} icon={icon} isActive={isActive} onClick={handleMenuItemClick} />
      : <Group title={title} icon={icon} />
  )
}
