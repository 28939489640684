import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'

interface GetCustomerDetailsState {
  customerDetails?: CustomerDetails
  loading: LoadingType
  error: string
}

export type CustomerStatusOptions = 'Active' | 'Expired'

export interface CustomerDetails {
  id: number
  name: string
  joinedDate: string
  totalEntitiesSubscribed: number
  subscriptionStartDate: string
  nextRenewDate: string
  status: CustomerStatusOptions
  entitiesAssessedCount: number
  teamMembersCount: number
  totalCompletedAssessmentsCount: number
  currentSubscriptionCompletedAssessmentsCount: number
  signedOutreachLetterPath?: string
  customerSubsidiariesCount: number
  canSendSSOInvites: boolean
}

const initialState: GetCustomerDetailsState = {
  customerDetails: undefined,
  loading: 'idle',
  error: ''
}

interface GetCustomerDetailsParams {
  customerId: number
}

export const getCustomerDetails = createAsyncThunk<
  CustomerDetails,
  GetCustomerDetailsParams,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('customers/getCustomerDetails', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<CustomerDetails>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/customers/${request.customerId}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getCustomerDetailsSlice = createSlice({
  name: 'get-customers',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerDetails.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getCustomerDetails.fulfilled, (state, { payload }) => {
      state.customerDetails = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getCustomerDetails.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = getCustomerDetailsSlice.actions
