import React, { useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem, Theme, Typography } from '@mui/material'
import { MenuListItemProps } from './menu-list-item'
import { AllPermissions, AnyPermissions, usePermission } from '../hooks/use-permission'

interface ActionMenuProps {
  index: number
  itemLength: number
  menuListItems: MenuListItemProps[]
  contextMenuIndicatorMarginLeft: number,
  isContextMenuDisabled?: boolean
}

export const ActionMenu = ({ index, itemLength, menuListItems, contextMenuIndicatorMarginLeft, isContextMenuDisabled }: ActionMenuProps) => {
  const [anchorEls, setAnchorEls] = useState<(null | HTMLElement)[]>(Array(itemLength).fill(null))

  const handleClick = (event: React.MouseEvent<HTMLElement>, rowIndex: number) => {
    const newAnchorEls = [...anchorEls]
    newAnchorEls[rowIndex] = event.currentTarget
    setAnchorEls(newAnchorEls)
  }

  const handleClose = (rowIndex: number) => {
    const newAnchorEls = [...anchorEls]
    newAnchorEls[rowIndex] = null
    setAnchorEls(newAnchorEls)
  }

  const handleItemClick = (onClick?: () => void) => {
    if (onClick) {
      onClick()
    }
    handleClose(index)
  }

  if (!menuListItems.filter(_ => _.show).length) {
    return null
  }

  return (
      <>
        <IconButton onClick={(e) => handleClick(e, index)}
          disabled={isContextMenuDisabled}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEls[index]}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          open={Boolean(anchorEls[index])}
          PaperProps={{
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              ml: contextMenuIndicatorMarginLeft,
              '& .MuiAvatar-root': {
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 15,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          sx={{
            '& .MuiButtonBase-root': {
              padding: 0
            }
          }}
          onClose={() => handleClose(index)}
        >
          {menuListItems.map((m, index) => m.show && (
            <ActionMenuItem
              key={`${m.title}-${index}`}
              {...m}
              onClick={() => handleItemClick(m.onClick)}
            />
          ))}
        </Menu>
      </>
  )
}

export const ActionMenuItem = ({
  title,
  icon,
  permissions,
  condition,
  show,
  onClick
}: MenuListItemProps) => {
  const permissionArray = usePermission(permissions)
  const [displayMenu, setDisplayMenu] = useState(false)

  useEffect(() => {
    if (!show) {
      return
    }
    if (permissionArray.length) {
      const result = (condition === undefined || condition === 'All')
        ? AllPermissions(permissionArray)
        : AnyPermissions(permissionArray)
      setDisplayMenu(result)
    } else {
      setDisplayMenu(true)
    }
  }, [show, permissionArray, condition])

  if (!displayMenu) {
    return null
  }

  return (<MenuItem onClick={onClick}>
    {icon}
    <Typography variant='h6' sx={{ color: (theme) => theme.palette.primary.dark, mr: 1, mb: 1, mt: 1 }}>{title}</Typography>
  </MenuItem>

  )
}

export interface ContextMenuItemProps {
    onClick: () => void
    show?: boolean
  }

export const createContextMenuItem =
  (title: string, icon: React.ReactElement, permissions: string[], onClick: () => void, show?: boolean): MenuListItemProps => {
    return {
      type: 'NavItem',
      title,
      to: '',
      icon: React.cloneElement(icon, {
        sx: {
          color: (theme: Theme) => theme.palette.primary.dark,
          ml: 1,
          mr: 1,
          fontSize: (theme: Theme) => theme.typography.pxToRem(12)
        }
      }),
      permissions,
      condition: 'All',
      show: show ?? true,
      onClick
    }
  }
