import { Grid, Stack, Typography } from '@mui/material'
import { TableSearch, TableSearchProps } from './table-search'

export interface TableHeaderProps extends TableSearchProps {
  title?: string
  description?: string
}

export const TableHeader = ({
  title, description, searchValue,
  onSearchClick, onResetSearchClick, onSearchTextChange
}: TableHeaderProps) =>
  <Grid container>
    {!!title && <Grid item xs>
      <Stack spacing={1.5}>
        <Typography variant='h3'>{title}</Typography>
        {!!description && <Typography color={({ palette }) => palette.text.secondary}>{description}</Typography>}
      </Stack>
    </Grid>}
    <Grid container item xs justifyContent="flex-end" alignItems="flex-end">
      <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
    </Grid>
  </Grid>
