import { ChangeEvent, MouseEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Autocomplete, Box, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'

import TabPanel from '../../../components/tab-panel'
import { formatDate } from '../../../utils/functions'
import AnalystUsersList from '../analyst/analyst-users-list'
import { ActionMenu } from '../../../components/action-menu'
import { usePermission } from '../../../hooks/use-permission'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { InternalNotes } from '../../../components/internal-notes'
import { LocationState } from '../../../interfaces/location-state'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { MenuListItemProps } from '../../../components/menu-list-item'
import PaginatedTable, { Column } from '../../../components/paginated-table'
import { AnalystUsersItem, getAnalystUsers } from '../get-analyst-users-slice'
import { TableSearch, TableSearchProps } from '../../../components/table-search'
import { getAnalystBasicInfo } from '../../analyst-tools/analysts/get-analyst-basic-info-slice'
import { setSelectedAssessmentInternalNote } from '../../../components/set-internal-notes-slice'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../components/assessment-on-hold-dialog-form'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../components/expire-assessment-confirmation-dialog'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem } from '../components/assessments-action-menu'
import { getUnderReviewAssessmentRequests, resetLoadingStatus, setPageNumber, setPageSize, setSearchText, setShowAssignedToSelectedAnalyst, setTabFilter, UnderReviewAssessmentRequestsItem } from './get-under-review-assessment-requests-slice'
import { AssessmentFooter } from '../components/assessment-footer'

const underReviewColumns: Column[] = [
  { id: 'assignedAnalyst', label: 'Assigned To', minWidth: 220, className: 'wrapped-text' },
  { id: 'orderDate', label: 'Date Ordered', minWidth: 100, align: 'left' },
  { id: 'submittedDate', label: 'Date Submitted', minWidth: 100, align: 'left' },
  { id: 'reportConfigurationId', label: 'Assessment Type', minWidth: 90 },
  { id: 'customer', label: 'Customer Name', minWidth: 175, className: 'wrapped-text' },
  { id: 'entityAssessed', label: 'Entity Assessed Name', minWidth: 175, className: 'wrapped-text' },
  { id: 'contractName', label: 'Assessment Subject', minWidth: 200, className: 'wrapped-text' }
]

const clarificationsRequiredColumns: Column[] = [
  { id: 'assignedAnalyst', label: 'Assigned To', minWidth: 220, className: 'wrapped-text' },
  { id: 'submittedDate', label: 'Date Submitted', minWidth: 100, align: 'left' },
  { id: 'dateFirstClarificationSent', label: 'Date First Clarification Sent', minWidth: 100, align: 'left' },
  { id: 'reportConfigurationId', label: 'Assessment Type', minWidth: 90 },
  { id: 'customer', label: 'Customer Name', minWidth: 175, className: 'wrapped-text' },
  { id: 'entityAssessed', label: 'Entity Assessed Name', minWidth: 175, className: 'wrapped-text' },
  { id: 'contractName', label: 'Assessment Subject', minWidth: 200, className: 'wrapped-text' }
]

export const getAssessmentColumns = (status: string): Column[] => {
  const columnMap: { [key: string]: Column[] } = {
    UnderReview: underReviewColumns,
    ClarificationsRequired: clarificationsRequiredColumns
  }

  return columnMap[status]
}

const tabs = {
  all: { id: 'all', label: 'All' },
  unassigned: { id: 'unassigned', label: 'Unassigned' },
  assignedTo: { id: 'assignedTo', label: 'Assigned To' }
}

export const UnderReviewAssessments = () => {
  const dispatch = useAppDispatch()
  const {
    getUnderReviewAssessmentRequestsState, getAnalystUsersState,
    assignAnalystUserToAssessmentRequestsState, updateExpiredAssessmentState,
    getAnalystBasicInfoState
  } = useAppSelector((state) => state)
  const [writeInternalNotes, writeAssignment, writeAssessment] = usePermission([AnilityScopes.Write.InternalNotes,
    AnilityScopes.Write.Assignment, AnilityScopes.Write.Assessment])
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as LocationState | undefined
  const { user } = useAuth0()

  const { items, pageSize, pageNumber, totalCount } = getUnderReviewAssessmentRequestsState
  const loading = getUnderReviewAssessmentRequestsState.loading === 'loading' || updateExpiredAssessmentState.loading === 'loading' ||
    assignAnalystUserToAssessmentRequestsState.loading === 'loading' || getAnalystUsersState.loading === 'loading' || getAnalystBasicInfoState.loading === 'loading'
  const [showInternalNotes, setShowInternalNotes] = useState(false)
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<number | null>(null)

  const defaultTab = tabs.all.id
  const [selectedTab, setSelectedTab] = useState<string>(tabs.all.id)

  const [tabSearchValues, setTabSearchValues] = useState<Record<string, string>>({ all: '', unassigned: '', assignedTo: '' })
  const [tabPageNumberValues, setTabPageNumberValues] = useState<Record<string, number>>({ all: 1, unassigned: 1, assignedTo: 1 })

  const [selectedAnalystId, setSelectedAnalystId] = useState<number | undefined>(undefined)

  const defaultStatus = 'UnderReview'
  const status = state?.status ?? defaultStatus

  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    const currentPageNumber = selectedPage + 1
    selectedTab === tabs.all.id && setTabPageNumberValues({ ...tabPageNumberValues, all: currentPageNumber })
    selectedTab === tabs.unassigned.id && setTabPageNumberValues({ ...tabPageNumberValues, unassigned: currentPageNumber })
    selectedTab === tabs.assignedTo.id && setTabPageNumberValues({ ...tabPageNumberValues, assignedTo: currentPageNumber })

    dispatch(setPageNumber(currentPageNumber))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    selectedTab === tabs.all.id && setTabSearchValues({ ...tabSearchValues, all: event.target.value })
    selectedTab === tabs.unassigned.id && setTabSearchValues({ ...tabSearchValues, unassigned: event.target.value })
    selectedTab === tabs.assignedTo.id && setTabSearchValues({ ...tabSearchValues, assignedTo: event.target.value })
  }

  const handleResetSearch = () => {
    selectedTab === tabs.all.id && setTabSearchValues({ ...tabSearchValues, all: '' })
    selectedTab === tabs.unassigned.id && setTabSearchValues({ ...tabSearchValues, unassigned: '' })
    selectedTab === tabs.assignedTo.id && setTabSearchValues({ ...tabSearchValues, assignedTo: '' })

    dispatch(setSearchText(''))
  }

  const handleAnalystChange = ({ value }: AnalystUsersItem) => {
    setSelectedAnalystId(value)
    dispatch(setShowAssignedToSelectedAnalyst(value))
  }

  const handleSearch = () => {
    dispatch(setSearchText(tabSearchValues[selectedTab]))
    selectedTab === tabs.all.id && setTabPageNumberValues({ ...tabPageNumberValues, all: 1 })
    selectedTab === tabs.unassigned.id && setTabPageNumberValues({ ...tabPageNumberValues, unassigned: 1 })
    selectedTab === tabs.assignedTo.id && setTabPageNumberValues({ ...tabPageNumberValues, assignedTo: 1 })
  }

  const handleExpireAssessmentSubmitSuccess = () => {
    dispatch(getUnderReviewAssessmentRequests({
      status
    }))
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    dispatch(resetLoadingStatus(false))
  }

  const handleInternalNotesClick = (item: UnderReviewAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const handleUnderReviewAssessmentDetailsClick = (
    underReviewAssessmentRequestDetails: UnderReviewAssessmentRequestsItem
  ) => {
    if (!underReviewAssessmentRequestDetails) {
      navigate(-1)
    }
    navigate(`/report/under-review-assessments/workspace/${underReviewAssessmentRequestDetails.id}`, {
      state: {
        status
      }
    })
  }

  const defaultSelectedAnalystId = getAnalystBasicInfoState?.analystDetails?.id
  const analystId = selectedAnalystId || defaultSelectedAnalystId

  const handleTabChange = (_: SyntheticEvent, tabValue: string) => {
    setSelectedTab(tabValue)
    dispatch(setTabFilter(tabValue))
    if (analystId) dispatch(setShowAssignedToSelectedAnalyst(analystId))
    dispatch(setSearchText(tabSearchValues[tabValue]))
    dispatch(setPageNumber(tabPageNumberValues[tabValue]))
    dispatch(resetLoadingStatus(false))
  }

  const generateContextMenu = (item: UnderReviewAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => {
          setShowAssessmentOnHoldDialogForm({
            show: true,
            assessmentRequestId: item.id
          })
        }
      })
    ]
  }

  useEffect(() => {
    if (!state) {
      navigate(location.pathname, { state: { status: defaultStatus }, replace: true })
    }
  }, [state, navigate])

  useEffect(() => {
    setSelectedTab(defaultTab)
    dispatch(setTabFilter(defaultTab))

    setTabSearchValues({ all: '', unassigned: '', assignedTo: '' })
    dispatch(setSearchText(''))

    setSelectedAnalystId(defaultSelectedAnalystId)
    dispatch(setShowAssignedToSelectedAnalyst(defaultSelectedAnalystId))

    setTabPageNumberValues({ all: 1, unassigned: 1, assignedTo: 1 })
    dispatch(setPageNumber(1))

    dispatch(resetLoadingStatus(true))
  }, [status])

  useEffect(() => {
    !!user && user.sub && dispatch(getAnalystBasicInfo({
      auth0Id: user?.sub
    }))
  }, [user])

  useEffect(() => {
    dispatch(resetLoadingStatus(true))
  }, [])

  useEffect(() => {
    if (getUnderReviewAssessmentRequestsState.loading === 'idle') {
      dispatch(getUnderReviewAssessmentRequests({
        status
      }))
    }
  }, [getUnderReviewAssessmentRequestsState.loading, status])

  useEffect(() => {
    if (getAnalystUsersState.loading === 'idle') {
      dispatch(getAnalystUsers())
    }
  }, [getAnalystUsersState.loading])

  const getTableCellStyle = () => ({
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    cursor: 'pointer'
  })

  const columns = getAssessmentColumns(status)

  const formattedColumns = columns.map((item) => {
    return {
      ...item,
      onRowClick: item.id === 'assignedAnalyst' && writeAssignment ? () => { } : undefined
    }
  })

  const formattedUnderReviewAssessments = items.map((item, index) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      submittedDate: formatDate(item.submittedDate),
      dateFirstClarificationSent: formatDate(item.dateFirstClarificationSent),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label,
      rowInlineStyles: getTableCellStyle(),
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.id}
          index={index}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          value={item.assignedAnalyst!}
          onSuccess={() => {
            dispatch(getUnderReviewAssessmentRequests({
              status
            }))
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label
    }
  })
  const page = { totalCount, size: pageSize, number: pageNumber }

  const filteredTabs = status === 'ClarificationsRequired'
    ? Object.fromEntries(Object.entries(tabs).filter(([key, _]) => key !== 'unassigned'))
    : tabs
  const renderTabs = () => {
    return Object.entries(filteredTabs).map(([_, { id, label }]) =>
      (
      <Tab
        key={id}
        value={id}
        label={label}
        id={`tab-${id}`}
        aria-controls={`tabpanel-${id}`}
        sx={{ textTransform: 'none', width: 200 }}
      />
      ))
  }

  const pageTitle: { [key: string]: string } = {
    UnderReview: 'Under Review',
    ClarificationsRequired: 'Clarifications Required'
  }
  const pageSubtitle: { [key: string]: string } = {
    UnderReview: 'Assessments that are under review',
    ClarificationsRequired: 'Assessments that require clarification'
  }

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <Stack spacing={1.5} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography variant='h3'>{pageTitle[status]}</Typography>
          <Typography color={({ palette }) => palette.text.secondary}>{pageSubtitle[status]}</Typography>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label={`${status.toLowerCase()}-assessment-tabs`}>
            {renderTabs()}
          </Tabs>
        </Box>
        {Object.entries(filteredTabs).map(([_, { id }]) => (
          <TabPanel key={`${status.toLowerCase()}-assessment-${id}`} id={`${status.toLowerCase()}-assessment-${id}`} selectedTab={selectedTab} value={id}>
            <PaginatedTable
              columns={formattedColumns}
              items={formattedUnderReviewAssessments}
              headerComponent={
                <HeaderComponent
                  searchValue={tabSearchValues[selectedTab]}
                  selectedTab={selectedTab}
                  selectedAnalystId={analystId}
                  analystUsers={getAnalystUsersState.items}
                  onSearchTextChange={handleSearchTextChange}
                  onSearchClick={handleSearch}
                  onResetSearchClick={handleResetSearch}
                  onChangeAnalyst={handleAnalystChange}
                />}
              footerComponent={
                <AssessmentFooter
                  page={page}
                  onTablePageChange={handlePageChange}
                  onTablePageSizeChange={handlePageSizeChange}
                />
              }
              renderMenuRowActions={(item, index, itemLength) => {
                return (
                  <ActionMenu
                    isContextMenuDisabled={false}
                    contextMenuIndicatorMarginLeft={0.5}
                    menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
                )
              }}
              onRowClick={handleUnderReviewAssessmentDetailsClick}
            />
          </TabPanel>))}

      </Box>
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })}
      />
      <AnilityBackdrop open={loading} />
    </>
  )
}
interface UnderReviewHeaderProps extends TableSearchProps {
  selectedTab: string
  analystUsers: AnalystUsersItem[]
  selectedAnalystId?: number
  onChangeAnalyst?: (item: AnalystUsersItem) => void;
}

const HeaderComponent = ({
  searchValue, selectedTab, analystUsers, selectedAnalystId,
  onSearchClick, onResetSearchClick, onSearchTextChange, onChangeAnalyst
}: UnderReviewHeaderProps) => {
  const selectedAnalyst = analystUsers?.find(_ => _.value === selectedAnalystId)
  return <Grid container justifyContent="flex-end" alignItems="flex-end" gap={2}>
    {selectedTab === tabs.assignedTo.id && <Grid item xs={2}>
      <Autocomplete
        disableClearable
        options={analystUsers}
        value={selectedAnalyst}
        onChange={(_, item) => onChangeAnalyst?.(item)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={!selectedAnalyst?.value ? 'Please select an Analyst' : ''}
          />
        )}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: 0,
            '.MuiOutlinedInput-notchedOutline': {
              borderTop: 'none',
              borderRight: 'none',
              borderLeft: 'none',
              borderRadius: 0
            },
            '.MuiAutocomplete-endAdornment': {
              top: '8px'
            },
            '.MuiAutocomplete-input': {
              paddingBottom: '5px'
            }
          }
        }}
      />
    </Grid>}
    <Grid item xs={2}>
      <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
    </Grid>

  </Grid>
}
