import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Alert, Box, Grid, IconButton, TextField, useTheme } from '@mui/material'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Check, DeleteOutlined, Clear } from '@mui/icons-material'

import { CustomDialogForm } from '../../../../components/custom-dialog-form'
import { CustomerSubsidiaryFormRequest } from './customer-subsidiary'
import { customerSubsidiaryDetailValidationSchema } from '../../../../validations/customer-subsidiary-validation-schema'
import { SimpleUploadButton } from '../../../../components/simple-upload-button'
import { DownloadLink } from '../../../../components/download-link'
import { Colors } from '../../../../theme/anility-dark-theme'

interface CustomerSubsidiaryFormDialogProps {
  open: boolean;
  defaultValues: CustomerSubsidiaryFormRequest
  subLabel: string
  apiError: string;
  onSubmit: (formValues: CustomerSubsidiaryFormRequest) => void;
  onClose: () => void;
  onDirectUploadFile?: (file : File) => void;
  onDeleteFile?: () => void
}
export const CustomerSubsidiaryFormDialog = ({ defaultValues, open, subLabel, apiError, onSubmit, onDirectUploadFile, onDeleteFile, onClose }: CustomerSubsidiaryFormDialogProps) => {
  const theme = useTheme()
  const [tempUploadedFile, setTempUploadedFile] = useState<File | undefined>(undefined)
  const fileUploadInputRef = useRef<HTMLInputElement>(null)

  const validationSchema = customerSubsidiaryDetailValidationSchema.shape<Partial<Record<keyof CustomerSubsidiaryFormRequest, yup.AnySchema>>>({})

  const {
    handleSubmit: handleFormSubmit, register, formState: { errors, isDirty }, reset: resetForm
  } = useForm<CustomerSubsidiaryFormRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues
  })

  useEffect(() => {
    defaultValues?.id && resetForm(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    resetForm()
    setTempUploadedFile(undefined)
  }, [open])

  const handleSubmit = (formValues: CustomerSubsidiaryFormRequest) => {
    onSubmit({ ...formValues, customerId: defaultValues.customerId, signedOutreachLetter: tempUploadedFile })
  }

  const handleUploadFileChange = (e: ChangeEvent<any>) => {
    if (e.target.files?.length > 0) {
      const file = e.target.files[0]
      onDirectUploadFile ? onDirectUploadFile(file) : setTempUploadedFile(file)
    }
  }

  const onClearFile = () => {
    setTempUploadedFile(undefined)
    if (fileUploadInputRef.current) {
      fileUploadInputRef.current.value = ''
    }
  }

  const handleClose = () => {
    resetForm()
    onClose()
  }

  return (
    <>
      <CustomDialogForm
        maxWidth={800}
        onClose={handleClose}
        primaryText='Save'
        title='Customer Subsidiary'
        open={open}
        showCloseDialogIcon={true}
        disablePrimary={!isDirty}
        handleSubmit={handleFormSubmit(handleSubmit)}
      >
        <Grid item container pt={1} pb={4}>
          <Grid typography='body1' item xs={12}>
            {subLabel}
          </Grid>
        </Grid>
        <Grid container spacing={2} pb={1} pt={3}>
          <Grid item xs={12} pb={1}>
            <TextField
              label='Name'
              error={!!errors.name}
              helperText={errors.name ? errors.name?.message : ' '}
              InputLabelProps={{ shrink: true, style: { color: theme.palette.primary.main } }}
              fullWidth {...register('name')}
            />
          </Grid>
          <Grid item xs={12} pb={1}>
            <TextField
              label='Domain'
              disabled={!!defaultValues?.id}
              error={!!errors.domainName}
              helperText={errors.domainName ? errors.domainName?.message : ' '}
              InputLabelProps={{ shrink: true, style: { color: theme.palette.primary.main } }}
              fullWidth {...register('domainName')}
            />
          </Grid>
          <Grid item pb={1}>
            <Box display="flex">
              {defaultValues?.signedOutreachLetterPath
                ? <Box display="flex" alignItems="center" sx={{ mt: 0.25, mb: 0.25 }}>
                    <Check fontSize='small' style={{ color: '#00A664' }} />
                    <DownloadLink url={`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${defaultValues.customerId}/subsidiary/${defaultValues.id}/download-signed-outreach-letter`}>Signed Engagement Letter Provided</DownloadLink>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{ color: Colors.primaryText, ml: 2 }}
                      onClick={onDeleteFile}
                      title='Delete Signed Engagement Letter'
                  >
                    <DeleteOutlined />
                  </IconButton>
                </Box>
                : <SimpleUploadButton
                  id={'signedOutreachLetter'}
                  acceptedFiles="application/pdf"
                  uploadText='Upload Signed Engagement Letter'
                  onUploadFile={handleUploadFileChange}
                  ref={fileUploadInputRef}
                />
                }
            </Box>
            {tempUploadedFile &&
                <Box display="flex" alignItems="center" >
                  <span>File Name: {tempUploadedFile.name} </span><Clear onClick={onClearFile}/></Box>}
          </Grid>

        </Grid>
        {apiError && <Grid container pt={2}>
            <Grid item xs={12}>
              <Alert severity='error'>{apiError}</Alert>
            </Grid>
          </Grid>}
      </CustomDialogForm>
    </>
  )
}
