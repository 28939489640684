import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import axios from 'axios'
import { AppDispatch, RootState } from '../../../store/store'

interface GetEntityAssessedDetailsState {
  entityAssessedDetails?: EntityAssessedDetails
  loading: LoadingType
  error: string
}

export interface EntityAssessedDetails {
  id: number
  entityName: string
  onboardingDate: string
  teamMembersCount: number
  totalCompletedAssessmentsCount: number
  entityAssessedRespondAvgTimeMs: number
  profileLink: string
}

const initialState: GetEntityAssessedDetailsState = {
  entityAssessedDetails: undefined,
  loading: 'idle',
  error: ''
}

interface GetEntityAssessedDetailsParams {
  entityAssessedId: number
}

export const getEntityAssessedDetails = createAsyncThunk<
  EntityAssessedDetails,
  GetEntityAssessedDetailsParams,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('entityAssessed/getEntityAssessedDetails', async (request, thunkAPI) => {
  const { tokenState } = thunkAPI.getState()

  try {
    const response = await axios.get<EntityAssessedDetails>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/entityAssessed/${request.entityAssessedId}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue({ error })
  }
})

export const getEntityAssessedDetailsSlice = createSlice({
  name: 'get-entity-assessed-details',
  initialState,
  reducers: {
    resetLoadingStatus: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEntityAssessedDetails.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getEntityAssessedDetails.fulfilled, (state, { payload }) => {
      state.entityAssessedDetails = payload
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getEntityAssessedDetails.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})

export const { resetLoadingStatus } = getEntityAssessedDetailsSlice.actions
