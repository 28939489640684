import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { AnilityScopes } from './interfaces/anility-scopes'
import { getConfig } from './config'

export const Auth0ProviderWithNavigate = ({ children }: any) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState): void => {
    navigate({
      pathname: appState?.returnTo || window.location.pathname,
      search: appState?.search || ''
    }, { replace: true })
  }

  const config = getConfig()

  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
    onRedirectCallback,
    scope: `profile email openid
      ${AnilityScopes.Read.AdminDashboard}
      ${AnilityScopes.Read.Users}
      ${AnilityScopes.Write.Users}
      ${AnilityScopes.Read.Reports}
      ${AnilityScopes.Write.Reports}
      ${AnilityScopes.Read.ReportConfiguration}
      ${AnilityScopes.Read.ReportLogs}
      ${AnilityScopes.Read.DeletedAssessments}
      ${AnilityScopes.Read.Customers}
      ${AnilityScopes.Write.Customers}
      ${AnilityScopes.Write.Subscription}
      ${AnilityScopes.Read.InternalNotes}
      ${AnilityScopes.Write.InternalNotes}
      ${AnilityScopes.Read.EntityAssessed}
      ${AnilityScopes.Write.Assignment}
      ${AnilityScopes.Read.AnalystUsers}
      ${AnilityScopes.Read.AssessmentResponse}
      ${AnilityScopes.Write.AssessmentResponse}
      ${AnilityScopes.Write.Events}
      ${AnilityScopes.Read.AssessmentRequest}
      ${AnilityScopes.Write.AssessmentRequest}
      ${AnilityScopes.Write.Assessment}
      ${AnilityScopes.Write.InviteTeamMember}
    `
  }

  return (
    <Auth0Provider
    {...providerConfig}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
