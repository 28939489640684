import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone'

import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { Colors } from '../theme/anility-dark-theme'
import { DownloadLink } from './download-link'

const baseStyle: React.CSSProperties = {
  borderWidth: 1,
  borderRadius: 6,
  flex: 1,
  flexShrink: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderColor: '#57647280',
  borderStyle: 'dashed',
  transition: 'border .24s ease-in-out'
}

const focusedStyle: React.CSSProperties = {
  borderColor: Colors.hoverColor
}

const acceptStyle: React.CSSProperties = {
  borderColor: green.A400
}

const rejectStyle: React.CSSProperties = {
  borderColor: red.A400
}

const disabledStyle : React.CSSProperties = {
  background: '#F1EFF4',
  color: '#DBE0E7'
}

export type RejectedFile = FileRejection;

export interface CustomFile {
  fileName: string,
  fileSize: BigInt,
  downloadUrl: string,
}

interface UploadFileProps {
  acceptedFileTypes: Accept;
  maxFileCount?: number;
  maxFileSize?: number;
  title?: ReactNode | string;
  description?: ReactNode | string;
  overrideContent?: ReactNode | string;
  uploadedFiles?: CustomFile[]
  disabled?: boolean;
  customValidation?: (acceptedFiles: File[]) => Promise<File[]>;
  onFileAccept: (acceptedFiles: File[]) => void;
  onFileValidate: (rejectedFiles: FileRejection[]) => void;
  onFileDropAccepted?: (files: File[], event: DropEvent) => void
  onFileRemove?: (file: CustomFile) => void
}

export const UploadFile = (props: React.PropsWithChildren<UploadFileProps>) => {
  const defaultMaxFileCount = 1
  const defaultMaxFileSize = 5 * 1024 * 1024
  const maxFileSizeMB = (props.maxFileSize ?? defaultMaxFileSize) / 1024 / 1024
  const title = props.title ?? (
    <Box typography="h4" sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <CloudUploadOutlinedIcon sx={{ fontSize: 28, fontWeight: 400, m: 0.5 }} />
      <Typography
        variant="h4"
        sx={{ color: Colors.mainColor }}
      >
        Browse file
      </Typography>
      <Typography
        variant="h5"
        sx={{ color: Colors.secondaryText, m: 0.5 }}
      >
        Drag and drop files here
      </Typography>
    </Box>
  )
  const description = props.description ?? (
    <Box sx={{ textAlign: 'center', opacity: '50%' }} pt={1} typography="body2">
      <p>Max. File Count: {props.maxFileCount ?? defaultMaxFileCount}</p>
      <p>Max. File Size: {maxFileSizeMB}MB</p>
    </Box>
  )

  const [files, setFiles] = useState<File[]>([])
  const onDrop = async (acceptedFiles: any[], rejectedFiles: any[]) => {
    if (rejectedFiles?.length > 0) {
      props.onFileValidate(rejectedFiles)
    }
    if (props.customValidation) {
      const validatedFiles = await props.customValidation(files)
      setFiles(validatedFiles)
    } else {
      acceptedFiles.length > 0 && setFiles(acceptedFiles)
    }
  }

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      onDropAccepted: props.onFileDropAccepted,
      accept: props.acceptedFileTypes,
      maxFiles: props.maxFileCount ?? defaultMaxFileCount,
      maxSize: props.maxFileSize ?? defaultMaxFileSize,
      disabled: props.disabled
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(props.disabled ? disabledStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject, props.disabled]
  )

  const handleRemove = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    file: CustomFile
  ) => {
    event.stopPropagation()
    setFiles(files.filter((f) => f.name !== file.fileName))
    props.onFileRemove?.(file)
  }

  useEffect(() => {
    props.onFileAccept(files)
  }, [files])

  return (
    <>
    <Box
      {...getRootProps({ style, className: 'dropzone' })}
      className="upload-file"
      sx={{
        minHeight: '150px'
      }}
    >
      <input {...getInputProps()} />
      <Grid
        sx={{ margin: 'auto', fontColor: '#00000' }}
        flexDirection="column"
        justifyItems="start"
        rowGap={2}
      >
        {title}
        {!props.overrideContent && !files.length ? description : undefined}
      </Grid>
    </Box>
    <Box>
    {props.uploadedFiles?.length
      ? (
        <List sx={{ width: '100%' }}>
          {props.uploadedFiles?.map((f, index) => (
            <ListItem
              key={`${f.fileName}-${index}`}
              sx={{ pr: 0, pl: 0, pt: 1, pb: 1 }}
            >
              {f.fileName &&
              <>
                <DownloadLink url={f.downloadUrl}>{`${f.fileName} - ${f.fileSize} bytes`}</DownloadLink>
                <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={props.disabled}
                    sx={{ color: Colors.primaryText, ml: 2 }}
                    onClick={(e) => handleRemove(e, f)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </>
              }
            </ListItem>
          ))}
        </List>
        )
      : undefined}
      {!files.length ? props.overrideContent : undefined}
    </Box>
    </>
  )
}
