import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { formatDate } from '../../../../utils/functions'
import { EntityAssessedDetails } from '../get-entity-assessed-details-slice'

interface EntityAssessedDetailsHeaderProps
{
    entityAssessedDetails? : EntityAssessedDetails
    onViewProfile?: () => void
}

export const EntityAssessedDetailsHeader = ({
  entityAssessedDetails,
  onViewProfile
}: EntityAssessedDetailsHeaderProps) => {
  if (!entityAssessedDetails) {
    return null
  }
  return (
        <Card>
            <CardContent>
                <Box>
                    <Grid container rowGap={1}>
                        <Grid item xs={12} display="inline-flex">
                            <Typography variant="h3">{entityAssessedDetails?.entityName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>Joined On: {formatDate(entityAssessedDetails?.onboardingDate, 'MMMM, YYYY')}</Typography>
                        </Grid>
                        {!!onViewProfile && <Grid item xs={12}>
                            <Button variant='contained' color='primary' onClick={onViewProfile}>View Profile</Button>
                        </Grid>}
                    </Grid>
                </Box>
            </CardContent>
        </Card>
  )
}
