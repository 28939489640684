import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, Fab, Grid, Typography } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
export type CustomDialogFormProps =
  {
    open: boolean;
    title: string;
    secondaryText?: string;
    primaryText: string;
    onClose?: () => void;
    handleSubmit?: any;
    disablePrimary?: boolean;
    minWidth?: number;
    maxWidth?: number;
    showCloseDialogIcon?: boolean
    hideDialogActions?: boolean
    applyFixedWidth?: boolean | undefined
  }

export const CustomDialogForm = ({
  open, title, primaryText, secondaryText,
  handleSubmit, onClose, disablePrimary, minWidth, maxWidth, children, showCloseDialogIcon, applyFixedWidth, hideDialogActions
}: React.PropsWithChildren<CustomDialogFormProps>) => {
  const handleClose = (_event: any, reason?: string) => {
    if (reason && reason === 'backdropClick') {
      return
    }
    onClose?.()
  }

  return (
    <Dialog open={open} sx={{ zIndex: 2, overflow: 'hidden' }} maxWidth={applyFixedWidth ? false : undefined} onClose={handleClose}>
      <Box minWidth={minWidth ?? 500} maxWidth={maxWidth ?? 500} py={3}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2" style={{ flex: '1', textAlign: 'center' }} px={1}>
            {title}
          </Typography>
          {showCloseDialogIcon && (
            <Box style={{ position: 'absolute', right: 25, transform: 'translateY(0%)' }}>
              <Fab style={{ borderRadius: 0 }} color='primary' size='small' onClick={onClose}>
                <CloseRoundedIcon fontSize='small' />
              </Fab>
            </Box>
          )}

        </Box>
        <DialogContent>
          <Grid container justifyContent="center" textAlign="center" sx={{ whiteSpace: 'pre-wrap' }} px={1}>
            {children}
          </Grid>
        </DialogContent>
        {(!hideDialogActions ?? false) && <DialogActions>
          <Grid container columnGap={2} px={3}>
            {secondaryText &&
              <Grid item xs>
                <Button variant="contained" color="secondary" onClick={onClose} fullWidth>{secondaryText ?? 'Previous'}</Button>
              </Grid>
            }
            <Grid item xs sx={
              !secondaryText
                ? { display: 'flex', justifyContent: 'center', alignItems: 'center' }
                : {}
            }>
              <Button
                variant="contained" color="primary" type="button" disabled={!!disablePrimary} onClick={handleSubmit} sx={{
                  width:
                    secondaryText ? '100%' : '50%'
                }}>{primaryText ?? 'Continue'}</Button>

            </Grid>
          </Grid>
        </DialogActions>}

      </Box>
    </Dialog>
  )
}
