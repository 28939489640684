export const AnilityScopes = {
  Read: {
    AdminDashboard: 'read:admin_dashboard',
    Reports: 'read:reports',
    ReportConfiguration: 'read:reports_configuration',
    ReportLogs: 'read:report_logs',
    Users: 'read:users',
    DeletedAssessments: 'read:deleted_assessments',
    Customers: 'read:customers',
    InternalNotes: 'read:internal_notes',
    EntityAssessed: 'read:entity_assessed',
    AnalystUsers: 'read:analyst_users',
    AssessmentResponse: 'read:assessment_response',
    AssessmentRequest: 'read:assessment_request'
  },
  Write: {
    Reports: 'write:reports',
    Users: 'write:users',
    Customers: 'write:customers',
    Subscription: 'write:subscription',
    InternalNotes: 'write:internal_notes',
    Assignment: 'write:assignment',
    AssessmentResponse: 'write:assessment_response',
    Events: 'write:events',
    AssessmentRequest: 'write:assessment_request',
    Assessment: 'write:assessment',
    InviteTeamMember: 'write:invite_team_member'
  }
}
