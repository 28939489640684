import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined'
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material'
import { ActionStep } from '../components/action-step'
import { useEventListener } from '../../../hooks/use-event-listener'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { approveUnderReviewAssessment } from './approve-under-review-assessment-slice'
import { getSubmissionReviewWorkspace } from './get-submission-review-workspace-slice'
import { Notification, NotificationMessage } from '../../../components/notification-message'
import { resetLoadingStatus as resetSetToClarificationsRequired, setAssessmentRequestToClarificationsRequired } from './set-assessment-request-to-clarifications-required-slice'
import { resetLoadingStatus as resetAssessmentRequestSubmissionReviewCompleted, setAssessmentRequestSubmissionReviewCompleted } from './set-assessment-request-submission-review-completed-slice'
import { resetLoadingStatus as resetAssessmentRequestFinancialStatementPreparation, setAssessmentRequestFinancialStatementPreparation } from './set-assessment-request-financial-statement-preparation-slice'

const SubmissionReviewWorkspace = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    appSettingsState, approveUnderReviewAssessmentState, getSubmissionReviewWorkspaceState, setAssessmentRequestFinancialStatementPreparationState,
    createReportWorkspaceState,
    setAssessmentRequestSubmissionReviewCompletedState,
    syncCrmDealState,
    setAssessmentRequestToClarificationsRequiredState
  } = useAppSelector((state) => state)
  const assessmentRequestId = Number(id)

  const financialExtractionToolUrl = appSettingsState.settings?.financialExtractionToolUrl
  const dispatch = useAppDispatch()
  const submissionReviewWorkspace = getSubmissionReviewWorkspaceState.submissionReviewWorkspace
  const loading = appSettingsState.loading === 'loading' || getSubmissionReviewWorkspaceState.loading === 'loading' || approveUnderReviewAssessmentState.loading === 'loading' ||
    setAssessmentRequestFinancialStatementPreparationState.loading === 'loading' || createReportWorkspaceState.loading === 'loading' ||
    setAssessmentRequestSubmissionReviewCompletedState.loading === 'loading' || syncCrmDealState.loading === 'loading' ||
    setAssessmentRequestToClarificationsRequiredState.loading === 'loading'
  const [notifications, setNotifications] = useState<Notification[]>([])
  const isFinancialStatementPrepared = submissionReviewWorkspace?.financialStatementPreparationCompleted
  const isSubmissionReviewCompleted = submissionReviewWorkspace?.isSubmissionReviewCompleted
  const [showApprovedUnderReviewSuccessDialog, setShowApprovedUnderReviewSuccessDialog] = useState(false)
  const [showClarificationsRequiredConfirmationDialog, setShowClarificationsRequiredConfirmationDialog] = useState(false)
  const isUnderReviewStatus = submissionReviewWorkspace?.assessmentStatus === 'Under Review'
  const status = submissionReviewWorkspace?.assessmentStatus.replace(/ +/g, '')

  useEffect(() => {
    if (!assessmentRequestId || isNaN(assessmentRequestId)) {
      navigate(-1)
    } else {
      dispatch(getSubmissionReviewWorkspace({
        assessmentRequestId
      }))
      dispatch(resetAssessmentRequestFinancialStatementPreparation())
      dispatch(resetAssessmentRequestSubmissionReviewCompleted())
      dispatch(resetSetToClarificationsRequired())
    }
  }, [assessmentRequestId])

  useEffect(() => {
    if (status) {
      navigate(location.pathname, { state: { status }, replace: true })
    }
  }, [status, navigate])

  const handleReloadSubmissionReviewWorkspace = (event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_API_BASE && event.data.methodToCall === 'reloadSubmissionReviewDetails') {
      dispatch(getSubmissionReviewWorkspace({
        assessmentRequestId
      }))
    }
  }
  useEventListener('message', handleReloadSubmissionReviewWorkspace)

  const handleApproveAndSubmit = useCallback(() => {
    dispatch(approveUnderReviewAssessment({
      assessmentRequestId,
      onSuccess: () => {
        setShowApprovedUnderReviewSuccessDialog(true)
      }
    }))
  }, [assessmentRequestId])

  const handleFinancialStatementPreparationChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAssessmentRequestFinancialStatementPreparation({
      assessmentRequestId,
      isFinancialStatementPrepared: event.target.checked,
      onSuccess: () => {
        dispatch(getSubmissionReviewWorkspace({
          assessmentRequestId
        }))
      }
    }))
  }

  const handleSubmissionReviewCompletedChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setAssessmentRequestSubmissionReviewCompleted({
      assessmentRequestId,
      isSubmissionReviewCompleted: event.target.checked,
      onSuccess: () => {
        dispatch(getSubmissionReviewWorkspace({
          assessmentRequestId
        }))
      }
    }))
  }

  const handleSetToClarificationsRequired = () => {
    dispatch(setAssessmentRequestToClarificationsRequired({
      assessmentRequestId,
      onSuccess: () => {
        navigate('/report/under-review-assessments', {
          state: {
            status
          }
        })
      }
    }))
    setShowClarificationsRequiredConfirmationDialog(false)
  }

  const handleClarificationsRequiredDialogVisibility = () => {
    setShowClarificationsRequiredConfirmationDialog(prev => !prev)
  }

  useEffect(() => {
    const tempNotifications: Notification[] = []
    if (approveUnderReviewAssessmentState.error) {
      tempNotifications.push({
        message: approveUnderReviewAssessmentState.error,
        severity: 'error'
      })
    }
    if (getSubmissionReviewWorkspaceState.error) {
      tempNotifications.push({
        message: getSubmissionReviewWorkspaceState.error,
        severity: 'error'
      })
    }
    if (setAssessmentRequestFinancialStatementPreparationState.loading === 'loaded' && approveUnderReviewAssessmentState.error === '') {
      tempNotifications.push({
        message: 'Financial statement preparation checklist updated.',
        severity: 'success'
      })
    }

    if ((setAssessmentRequestFinancialStatementPreparationState.loading === 'loaded' || setAssessmentRequestFinancialStatementPreparationState.loading === 'error') && setAssessmentRequestFinancialStatementPreparationState.error) {
      tempNotifications.push({
        message: setAssessmentRequestFinancialStatementPreparationState.error,
        severity: 'error'
      })
    }

    if (setAssessmentRequestSubmissionReviewCompletedState.loading === 'loaded' && setAssessmentRequestSubmissionReviewCompletedState.error === '') {
      tempNotifications.push({
        message: 'Submission review checklist updated.',
        severity: 'success'
      })
    }
    if ((setAssessmentRequestSubmissionReviewCompletedState.loading === 'loaded' || setAssessmentRequestSubmissionReviewCompletedState.loading === 'error') && setAssessmentRequestSubmissionReviewCompletedState.error) {
      tempNotifications.push({
        message: setAssessmentRequestSubmissionReviewCompletedState.error,
        severity: 'error'
      })
    }

    if (syncCrmDealState.error) {
      tempNotifications.push({
        message: syncCrmDealState.error,
        severity: 'error'
      })
    }
    if (syncCrmDealState.loading === 'loaded' && !syncCrmDealState.error) {
      tempNotifications.push({
        message: 'CRM deal synced.',
        severity: 'success'
      })
    }
    if (setAssessmentRequestToClarificationsRequiredState.loading === 'loaded' && setAssessmentRequestToClarificationsRequiredState.error === '') {
      tempNotifications.push({
        message: 'Assessment status is set to Clarifications Required',
        severity: 'success'
      })
    }
    if ((setAssessmentRequestToClarificationsRequiredState.loading === 'loaded' || setAssessmentRequestToClarificationsRequiredState.loading === 'error') && setAssessmentRequestToClarificationsRequiredState.error) {
      tempNotifications.push({
        message: setAssessmentRequestToClarificationsRequiredState.error,
        severity: 'error'
      })
    }
    setNotifications(tempNotifications)
  }, [approveUnderReviewAssessmentState.error, getSubmissionReviewWorkspaceState.error, setAssessmentRequestFinancialStatementPreparationState, setAssessmentRequestSubmissionReviewCompletedState, syncCrmDealState, setAssessmentRequestToClarificationsRequiredState])

  const approvedUnderReviewConfirmationDialog = (
    <CustomDialogForm
      open={showApprovedUnderReviewSuccessDialog}
      primaryText="Ok"
      handleSubmit={() => {
        navigate('/report/under-review-assessments', {
          state: {
            status
          }
        })
      }}
      maxWidth={600}
      title="Assessment Approved and Submitted"
      secondaryText="">
      The Report Workspace is being generated and will be available in &apos;In Progress&apos; in a few minutes. Click &#34;Ok&#34; to return to &apos;{submissionReviewWorkspace?.assessmentStatus}&apos;.
    </CustomDialogForm>
  )

  return (
    <>
      <Box p={4} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        rowGap: 0.2
      }}>
        <Card>
          <CardContent>
            <Box pl={2} pt={2} pr={2}>
              <Box display="inline-flex">
                <Typography variant="h3">Submission Review</Typography>
              </Box>
              <Box pt={3}>
                <AssessmentSubject assessmentSubject={submissionReviewWorkspace?.contractName} crmDealLink={submissionReviewWorkspace?.crmDealLink} />
                {isUnderReviewStatus && <Button variant="contained"
                    sx={{ mt: 1.5 }}
                    type="button" color="primary"
                    disabled={!submissionReviewWorkspace?.assignedAnalystUserId || submissionReviewWorkspace?.assessmentStatus !== 'Under Review'}
                    onClick={handleClarificationsRequiredDialogVisibility}>Clarifications Required
                </Button>}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <NotificationMessage items={notifications} />
        <ActionStep stepLabel="Step 1" title="Review Submission" endControl={<FormControlLabel control={<Checkbox checked={submissionReviewWorkspace?.isSubmissionReviewCompleted ?? false} onChange={handleSubmissionReviewCompletedChange} />} label="Completed" />}>
          <Grid container flexDirection="column" rowGap={2} pb={1}>
            <Grid item>
              <FormControlLabel
                label={<Typography variant="label" color={(theme) => theme.palette.text.secondary} sx={{ cursor: 'default' }}>Customer Submission</Typography>}
                labelPlacement="top"
                sx={{
                  margin: 0,
                  alignItems: 'flex-start',
                  '& .MuiLink-root': {
                    marginTop: 1
                  }
                }}
                control={
                  <Link target="_blank" href={`${submissionReviewWorkspace?.requestLink}?source=submission_review`} rel="opener">
                    {submissionReviewWorkspace?.customerName}
                  </Link>}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label={<Typography variant="label" color={(theme) => theme.palette.text.secondary} sx={{ cursor: 'default' }}>Entity Submission</Typography>}
                labelPlacement="top"
                sx={{
                  margin: 0,
                  alignItems: 'flex-start',
                  '& .MuiLink-root': {
                    marginTop: 1
                  }
                }}
                control={
                  <Link target="_blank" href={`${submissionReviewWorkspace?.responseLink}?source=submission_review`} rel="opener">
                    {submissionReviewWorkspace?.entityAssessedName}
                  </Link>}
              />
            </Grid>
            {submissionReviewWorkspace?.checklistLink && <Grid item>
              <FormControlLabel
                label={<Typography variant="label" color={(theme) => theme.palette.text.secondary} sx={{ cursor: 'default' }}>Submission Review Checklist Link</Typography>}
                labelPlacement="top"
                sx={{
                  margin: 0,
                  alignItems: 'flex-start',
                  '& .MuiLink-root': {
                    marginTop: 1
                  }
                }}
                control={
                  <Link target="_blank" href={submissionReviewWorkspace?.checklistLink} rel="opener">
                    <OpenInNewOutlined fontSize="small" color="primary" />
                  </Link>}
              />
            </Grid>}
          </Grid>
        </ActionStep>
        <ActionStep stepLabel="Step 2" title="Prepare Financial Statements" endControl={<FormControlLabel control={<Checkbox checked={submissionReviewWorkspace?.financialStatementPreparationCompleted ?? false} onChange={handleFinancialStatementPreparationChange} />} label="Completed" />}>
          <Grid container flexDirection="column" rowGap={2} pb={1}>
            <Grid item>
              <FormControlLabel
                label={<Typography variant="label" color={(theme) => theme.palette.text.secondary} sx={{ cursor: 'default' }}>Financial Extraction tool</Typography>}
                labelPlacement="top"
                sx={{
                  margin: 0,
                  alignItems: 'flex-start',
                  '& .MuiLink-root': {
                    marginTop: 1
                  }
                }}
                control={
                  <Link target="_blank" href={financialExtractionToolUrl ?? '#'} rel="opener">
                    Auxilis
                  </Link>}
              />
            </Grid>
            {submissionReviewWorkspace?.financialStatementModelLink && <Grid item>
              <FormControlLabel
                label={<Typography variant="label" color={(theme) => theme.palette.text.secondary} sx={{ cursor: 'default' }}>Financial Statement Model Link</Typography>}
                labelPlacement="top"
                sx={{
                  margin: 0,
                  alignItems: 'flex-start',
                  '& .MuiLink-root': {
                    marginTop: 1
                  }
                }}
                control={
                  <Link target="_blank" href={submissionReviewWorkspace?.financialStatementModelLink} rel="opener">
                    <OpenInNewOutlined fontSize="small" color="primary" />
                  </Link>}
              />
            </Grid>}
          </Grid>
        </ActionStep>
        <ActionStep stepLabel="Step 3" title="Approve Submission" endControl={
          <Button variant="contained"
            type="button" color="primary"
            disabled={!isFinancialStatementPrepared || !isSubmissionReviewCompleted}
            onClick={handleApproveAndSubmit}>Approve and Submit</Button>
        } />
      </Box>
      {approvedUnderReviewConfirmationDialog}
      <AnilityBackdrop open={loading} />
      {showClarificationsRequiredConfirmationDialog &&
        <CustomDialogForm
          open={showClarificationsRequiredConfirmationDialog}
          primaryText="Yes"
          handleSubmit={handleSetToClarificationsRequired}
          onClose={handleClarificationsRequiredDialogVisibility}
          title="Are you sure Clarifications are required from this Entity?"
          secondaryText="Cancel">
          Please confirm to move this request to &quot;Clarifications Required&quot;.
        </CustomDialogForm>
      }
    </>
  )
}

const AssessmentSubject = ({
  assessmentSubject,
  crmDealLink
}: {
  assessmentSubject?: string
  crmDealLink?: string
}) => {
  return crmDealLink
    ? (
      <Link variant="label" target="_blank" href={crmDealLink} sx={{ width: 'max-content', maxWidth: '100%' }}>{assessmentSubject}</Link>
      )
    : (
      <Typography variant="label" color={(theme) => theme.palette.text.secondary} sx={{ cursor: 'default' }}>{assessmentSubject}</Typography>
      )
}

export default SubmissionReviewWorkspace
