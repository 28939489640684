import { useEffect } from 'react'

import { CustomerSubsidiaryFormRequest } from './customer-subsidiary'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { getCustomerSubsidiaryDetails, resetGetSubsidiaryLoadingStatus } from './get-customer-subsidiary-details-slice'
import { CustomerSubsidiaryFormDialog } from './customer-subsidiary-form-dialog'
import { AnilityBackdrop } from '../../../../components/anility-backdrop'
import { resetUpdateCustomerSubsidiary, updateCustomerSubsidiary } from './update-customer-subsidiary-slice'
import { uploadCustomerSubsidiarySignedOutreachLetter } from './upload-customer-subsidiary-signed-outreach-letter-slice'
import { deleteCustomerSubsidiarySignedOutreachLetter } from './delete-customer-subsidiary-signed-outreach-letter-slice'

interface EditCustomerSubsidiaryFormDialogProps {
  selectedId: number;
  customerId: number;
  open: boolean;
  onSaveSuccess: () => void;
  onClose: () => void;
}
export const UpdateCustomerSubsidiaryForm = ({ selectedId, customerId, open, onSaveSuccess, onClose }: EditCustomerSubsidiaryFormDialogProps) => {
  const dispatch = useAppDispatch()
  const {
    getCustomerSubsidiaryDetailsState,
    updateCustomerSubsidiaryDetailsState,
    uploadCustomerSubsidiarySignedOutreachLetterState,
    deleteCustomerSubsidiarySignedOutreachLetterState
  } = useAppSelector(state => state)
  const { error } = updateCustomerSubsidiaryDetailsState

  useEffect(() => {
    dispatch(resetGetSubsidiaryLoadingStatus())
    dispatch(resetUpdateCustomerSubsidiary())
  }, [open])

  useEffect(() => {
    dispatch(getCustomerSubsidiaryDetails({ id: selectedId!, customerId }))
  }, [selectedId, customerId])

  const defaultValues: CustomerSubsidiaryFormRequest = {
    id: selectedId,
    customerId,
    name: getCustomerSubsidiaryDetailsState?.details?.name || '',
    domainName: getCustomerSubsidiaryDetailsState?.details?.domainName || '',
    signedOutreachLetterPath: getCustomerSubsidiaryDetailsState?.details?.signedOutreachLetterPath || ''
  }

  const handleSubmit = (formValues: CustomerSubsidiaryFormRequest) => {
    if (!selectedId) { return }
    const { id, name } = formValues

    dispatch(updateCustomerSubsidiary({
      id,
      customerId,
      name,
      onSuccess: () => {
        onSaveSuccess()
      }
    }))
  }

  const handleDirectUploadFile = (file : File) => {
    dispatch(uploadCustomerSubsidiarySignedOutreachLetter({
      customerSubsidiaryId: selectedId,
      customerId,
      signedOutreachLetter: file,
      onSuccess: () => {
        dispatch(getCustomerSubsidiaryDetails({ id: selectedId!, customerId }))
      }
    }))
  }

  const handleDeleteFile = () => {
    dispatch(deleteCustomerSubsidiarySignedOutreachLetter({
      customerId,
      customerSubsidiaryId: selectedId,
      onSuccess: () => {
        dispatch(getCustomerSubsidiaryDetails({ id: selectedId!, customerId }))
      }
    }))
  }

  return (
    <>
      <CustomerSubsidiaryFormDialog
          open={open}
          defaultValues={defaultValues}
          subLabel='You may edit the customer customer subsidiary details below'
          apiError={error}
          onClose={onClose}
          onSubmit={handleSubmit}
          onDirectUploadFile={handleDirectUploadFile}
          onDeleteFile={handleDeleteFile}
      />
      <AnilityBackdrop open={getCustomerSubsidiaryDetailsState.loading === 'loading' ||
                  updateCustomerSubsidiaryDetailsState.loading === 'loading' ||
                  uploadCustomerSubsidiarySignedOutreachLetterState.loading === 'loading' ||
                  deleteCustomerSubsidiarySignedOutreachLetterState.loading === 'loading' }
      />
    </>
  )
}
