import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import PaginatedTable, { Column } from '../../../components/paginated-table'
import { TableSearch, TableSearchProps } from '../../../components/table-search'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'
import { AnalystUsers, getAnalystUsersList, setPageNumber, setPageSize, setSearchText } from './get-analyst-users-list-slice'
import { formatDate } from '../../../utils/functions'

const columns: Column[] = [{
  id: 'firstName',
  label: 'First Name',
  minWidth: 150,
  align: 'left',
  className: 'wrapped-text'
},
{
  id: 'lastName',
  label: 'Last Name',
  minWidth: 150,
  className: 'wrapped-text'
},
{
  id: 'orderedAssessments',
  label: 'Ordered Assessments',
  minWidth: 150,
  align: 'center',
  className: 'wrapped-text'
},
{
  id: 'inProgressAssessments',
  label: 'In Progress Assessments',
  minWidth: 150,
  align: 'center',
  className: 'wrapped-text'
},
{
  id: 'completedAssessments',
  label: 'Completed Assessments',
  minWidth: 150,
  align: 'center',
  className: 'wrapped-text'
},
{
  id: 'joinedDate',
  label: 'Joined On',
  minWidth: 100
}]

const AnalystList = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getAnalystUsersListState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, searchText } = getAnalystUsersListState
  const loading = getAnalystUsersListState.loading === 'loading'

  const [localSearch, setLocalSearch] = useState(searchText)

  useEffect(() => {
    if (getAnalystUsersListState.loading === 'idle') {
      dispatch(getAnalystUsersList())
    }
  }, [getAnalystUsersListState.loading])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value)
  }

  const handleResetSearch = () => {
    setLocalSearch('')
    dispatch(setSearchText(''))
  }

  const handleSearch = () => {
    dispatch(setSearchText(localSearch))
  }

  const handleViewAnalystUser = (
    analystUser: AnalystUsers
  ) => {
    navigate(`${analystUser.id}`)
  }

  const getTableCellStyle = () => ({
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    cursor: 'pointer'
  })

  const page = { totalCount, size: pageSize, number: pageNumber }

  const formattedAnalystList = items.map((item) => {
    return {
      ...item,
      joinedDate: formatDate(item.joinedDate),
      rowInlineStyles: getTableCellStyle()
    }
  })
  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <PaginatedTable
          columns={columns}
          items={formattedAnalystList}
          headerComponent={<SearchComponent
            searchValue={localSearch}
            onSearchTextChange={handleSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={handleResetSearch} />}
          footerComponent={
            <PaginationComponent
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />}
          onRowClick={handleViewAnalystUser}
        />
      </Box >
      <AnilityBackdrop open={loading} />
    </>
  )
}

const SearchComponent = ({ searchValue, onSearchClick, onResetSearchClick, onSearchTextChange }: TableSearchProps) =>
  <Grid container>
    <Grid item xs={10}>
      <Stack spacing={1.5}>
        <Typography variant='h3'>Analysts</Typography>
        <Typography color={({ palette }) => palette.text.secondary}>List of Anility analysts</Typography>
      </Stack>
    </Grid>
    <Grid container item xs={2} justifyContent="flex-end" alignItems="flex-end">
      <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
    </Grid>
  </Grid>

const PaginationComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <>
    <Grid container>
      <Grid item xs={8} py={2}>
      </Grid>
      <Grid item xs={4}>
        <TablePagination page={page}
          onTablePageChange={onTablePageChange}
          onTablePageSizeChange={onTablePageSizeChange} />
      </Grid>
    </Grid>
  </>

export default AnalystList
