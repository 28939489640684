import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../hooks/hooks'
import { Notification, NotificationMessage } from '../../../../components/notification-message'

export const ReportWorkspaceViewNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const {
    getReportWorkspaceDetailsState, markWorkspaceAsDoneState,
    runReportWorkspaceStagesState, generateReportWorkspacePdfState,
    setAnalystCommentsState, uploadPPSRState, deletePPSRState,
    appSettingsState
  } = useAppSelector((state) => state)
  const { reportWorkspaceDetails } = getReportWorkspaceDetailsState

  useEffect(() => {
    const tempNotifications: Notification[] = []
    if (reportWorkspaceDetails?.status === 'Error') {
      tempNotifications.push({
        message: `There is an error in the report. Please contact ${appSettingsState.settings?.supportEmail}.`,
        severity: 'error'
      })
    }

    if (markWorkspaceAsDoneState.error) {
      tempNotifications.push({
        message: markWorkspaceAsDoneState.error,
        severity: 'error'
      })
    }

    if (runReportWorkspaceStagesState.error) {
      tempNotifications.push({
        message: `There was an error when performing ${runReportWorkspaceStagesState.actionName}. ${runReportWorkspaceStagesState.error}`,
        severity: 'error'
      })
    }

    if (generateReportWorkspacePdfState.error) {
      tempNotifications.push({
        message: `There was an error when generating the PDF. ${generateReportWorkspacePdfState.error}`,
        severity: 'error'
      })
    }

    if (setAnalystCommentsState.error) {
      tempNotifications.push({
        message: `There was an error when saving the analyst comments. ${setAnalystCommentsState.error}`,
        severity: 'error'
      })
    }

    if (runReportWorkspaceStagesState.loading === 'loaded') {
      tempNotifications.push({
        message: `${runReportWorkspaceStagesState.actionName?.slice(0, 1).toUpperCase()}${runReportWorkspaceStagesState.actionName?.substring(1)} completed successfully.`,
        severity: 'success'
      })
    }

    if (generateReportWorkspacePdfState.loading === 'loaded') {
      tempNotifications.push({
        message: 'PDF generated successfully.',
        severity: 'success'
      })
    }

    if (setAnalystCommentsState.loading === 'loaded') {
      tempNotifications.push({
        message: 'Analyst comments saved successfully.',
        severity: 'success'
      })
    }

    if (uploadPPSRState.loading === 'loaded' && deletePPSRState.loading === 'idle') {
      tempNotifications.push({
        message: 'PPSR File successfully uploaded.',
        severity: 'success'
      })
    }

    if (deletePPSRState.loading === 'loaded' && uploadPPSRState.loading === 'idle') {
      tempNotifications.push({
        message: 'PPSR File successfully deleted.',
        severity: 'success'
      })
    }

    reportWorkspaceDetails?.warnings?.forEach(warning => {
      tempNotifications.push({
        message: warning,
        severity: 'warning'
      })
    })

    setNotifications(tempNotifications)
  }, [reportWorkspaceDetails, markWorkspaceAsDoneState, runReportWorkspaceStagesState, generateReportWorkspacePdfState])

  return <NotificationMessage items={notifications} />
}
