import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { PaginatedTable, Column } from '../../../../components/paginated-table'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { formatDate, formatDuration } from '../../../../utils/functions'
import { TimeInfo } from '../../../report/components/time-info'
import { OrderedAssessmentRequestsItem } from '../../../report/ordered-assessments/get-ordered-assessment-requests-slice'
import { Grid } from '@mui/material'
import { TableSearch, TableSearchProps } from '../../../../components/table-search'
import { TablePagination, TablePaginationProps } from '../../../../components/table-pagination'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem } from '../../../report/components/assessments-action-menu'
import { setSelectedAssessmentInternalNote } from '../../../../components/set-internal-notes-slice'
import { MenuListItemProps } from '../../../../components/menu-list-item'
import { getAnalystUsers } from '../../../report/get-analyst-users-slice'
import AnalystUsersList from '../../../report/analyst/analyst-users-list'
import { usePermission } from '../../../../hooks/use-permission'
import { AnilityScopes } from '../../../../interfaces/anility-scopes'
import { getAnalystOrderedAssessmentRequests, setPageNumber, setPageSize } from './get-analyst-ordered-assessments-slice'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../../../report/components/expire-assessment-confirmation-dialog'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../../../report/components/assessment-on-hold-dialog-form'
import { useEventListener } from '../../../../hooks/use-event-listener'
import { ActionMenu } from '../../../../components/action-menu'

interface AnalystOrderedAssessmentsProps {
  analystId: number;
  searchValue: string;
  onSearchClick: () => void;
  onResetSearchClick: () => void;
  onSearchTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setShowInternalNotes: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchComponent = ({ searchValue, onSearchClick, onResetSearchClick, onSearchTextChange }: TableSearchProps) =>
  <Grid container justifyContent="flex-end" alignItems="flex-end">
    <TableSearch searchValue={searchValue} onSearchClick={onSearchClick} onResetSearchClick={onResetSearchClick} onSearchTextChange={onSearchTextChange} />
  </Grid>

const FooterComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='space-between'>
    <Grid item xs={8} py={2}>
      <TimeInfo />
    </Grid>
    <Grid item xs={4}>
      <TablePagination page={page}
        onTablePageChange={onTablePageChange}
        onTablePageSizeChange={onTablePageSizeChange} />
    </Grid>
  </Grid>

export const AnalystOrderedAssessments = ({ analystId, searchValue, onSearchClick, onResetSearchClick, onSearchTextChange, setShowInternalNotes }: AnalystOrderedAssessmentsProps) => {
  const columns: Column[] = [
    {
      id: 'assignedAnalyst',
      label: 'Assigned To',
      minWidth: 220,
      className: 'wrapped-text',
      onRowClick: () => { }
    },
    {
      id: 'orderDate',
      label: 'Date Ordered',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'reportConfigurationId',
      label: 'Assessment Type',
      minWidth: 90
    },
    {
      id: 'entityAssessed',
      label: 'Entity Assessed Name',
      minWidth: 175,
      className: 'wrapped-text'
    },
    {
      id: 'contractName',
      label: 'Assessment Subject',
      minWidth: 150,
      className: 'wrapped-text'
    },
    {
      id: 'nominatedContactName',
      label: 'Contact Name',
      minWidth: 125,
      className: 'wrapped-text'
    },
    {
      id: 'phoneNumber',
      label: 'Phone Number',
      minWidth: 125,
      className: 'wrapped-text'
    },
    {
      id: 'nominatedUserEmailAddress',
      label: 'Email Address',
      minWidth: 175,
      className: 'wrapped-text'
    },
    {
      id: 'timeElapsedSinceOrdering',
      label: 'Time Elapsed Since Ordering',
      minWidth: 150,
      className: 'wrapped-text'
    }
  ]

  const dispatch = useAppDispatch()
  const { getAnalystOrderedAssessmentRequestsState, getAnalystUsersState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } = getAnalystOrderedAssessmentRequestsState
  const [writeAssignment, writeAssessment] = usePermission([AnilityScopes.Write.Assignment, AnilityScopes.Write.Assessment])
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<number | null>(null)
  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const loadOrderedAssessmentsInAnalysts = () => {
    dispatch(getAnalystOrderedAssessmentRequests({
      analystId,
      status: 'CollectingRequiredInformation'
    }))
  }

  useEffect(() => {
    if (getAnalystOrderedAssessmentRequestsState.loading === 'idle') {
      loadOrderedAssessmentsInAnalysts()
    }
  }, [getAnalystOrderedAssessmentRequestsState.loading, analystId])

  useEffect(() => {
    dispatch(getAnalystUsers())
  }, [analystId])

  const handleReloadOrderedAssessments = (event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_API_BASE && event.data.methodToCall === 'reloadOrderedAssessmentsInAnalysts') {
      loadOrderedAssessmentsInAnalysts()
    }
  }
  useEventListener('message', handleReloadOrderedAssessments)

  const handleExpireAssessmentSubmitSuccess = () => {
    if (!analystId) {
      return
    }
    loadOrderedAssessmentsInAnalysts()
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    if (!analystId) {
      return
    }
    loadOrderedAssessmentsInAnalysts()
  }

  const handleInternalNotesClick = (item: OrderedAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const generateContextMenu = (item: OrderedAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => setShowAssessmentOnHoldDialogForm({
          show: true,
          assessmentRequestId: item.id
        })
      })
    ]
  }

  const handleSearch = () => {
    onSearchClick()
  }

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleViewCustomerSuperform = (item: OrderedAssessmentRequestsItem) => {
    if (!item.requestLink) return
    window.open(`${item.requestLink}?source=ordered_analyst`, 'blank')
  }

  const formattedOrderedAssessments = items.map((item: OrderedAssessmentRequestsItem, index: number) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.id}
          index={index}
          value={item.assignedAnalyst!}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          onSuccess={() => {
            loadOrderedAssessmentsInAnalysts()
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      rowInlineStyles: { cursor: 'pointer' }
    }
  })

  const page = useMemo(() => {
    return { totalCount, size: pageSize, number: pageNumber }
  }, [totalCount, pageSize, pageNumber])

  return (
    <>
      <PaginatedTable
        columns={columns}
        items={formattedOrderedAssessments}
        headerComponent={
          <SearchComponent
            searchValue={searchValue}
            onSearchTextChange={onSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={onResetSearchClick} />}
        footerComponent={
          <FooterComponent
            page={page}
            onTablePageChange={handlePageChange}
            onTablePageSizeChange={handlePageSizeChange}
          />
        }
        renderMenuRowActions={(item, index, itemLength) => {
          return (
            <ActionMenu
              isContextMenuDisabled={false}
              contextMenuIndicatorMarginLeft={0.6}
              menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
          )
        }}
        onRowClick={handleViewCustomerSuperform}
      />
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
       <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })} />
    </>
  )
}
